import React, {useState, useEffect} from "react";
import Flippy, { FrontSide, BackSide } from 'react-flippy';

const FlippableCoin = ({flipState, setFlipState}) => {

    const [localFlipState, setLocalFlipState] = useState(false);

    useEffect(() => {
        if (flipState === 0) {
            setLocalFlipState(false);
        } else if (flipState === 1) {
            setLocalFlipState(true);
        } 
    }, [flipState])

    return (
        <Flippy flipOnClick={false} isFlipped={localFlipState}>
            <FrontSide className="no-padding">
                <strong className="coin-holder" onClick={() => setFlipState(flipState === 0 ? 1 : 0)}>
                    <div className="img-wrapper">
                        <img className={``} src="/assets/images/coinfront.png" alt="coinfront" />
                    </div>
                </strong>
            </FrontSide>
            <BackSide className="no-padding">
                <strong className="coin-holder" onClick={() => setFlipState(flipState === 1 ? 0 : 1)}>
                    <div className="img-wrapper">
                        <img className={``} src="/assets/images/coinback.png" alt="coinback" />
                    </div>
                </strong>
            </BackSide>
        </Flippy>
    )
}

export default FlippableCoin;