import React, { useState } from "react";
import { apiProcessTxn, defaultChain, supportedChains} from "../../constants.js";
import { utils } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { getExplorerTransactionLink, useEthers } from "@usedapp/core";
import { chainData, chainLogo, chainName } from "components/common/helpers.js";
import { UNKNOWN_NAME } from "components/common/helpers.js";
import { GiCrossedChains } from "react-icons/gi";


export const Guide = () => {
  const {chainId} = useEthers();
    return (
      <div>
        <p>
          <b>INSTRUCTIONS:</b><br/>
            1.&nbsp;Connect your MetaMask Wallet.<br/>
            2.&nbsp;Choose Heads or Tails.<br/>
            3.&nbsp;Choose a flip amount.<br/>
            4.&nbsp;Click "Flip Now".<br/>
            5.&nbsp;Moon or Rug.<br/>
        </p><br/>
        <p>
          <b>What is a MetaMask Wallet?</b><br/>
          <p>MetaMask is a free crypto wallet software that <br/>
          can be connected to virtually any Ethereum-based <br/>
          platform. First add the MetaMask Extension to chrome,<br/>
          and follow the instructions to create a wallet.</p>
        </p><br/>
        <p>
          <b>How do I fund my MetaMask wallet?</b><br/>
          <p>Use an exchange such as Binance, FTX, or Coinbase<br/>
          to fund your MetaMask wallet. Purchase the {chainName(chainId)}({chainData(chainId)})<br/>
          currency and withdraw these funds into your MetaMask wallet.</p>
        </p><br/>
        <p>
          <b>How do I add the {chainName(chainId)} chain to metamask?</b><br/>
          <p>Connect your wallet to the site and click on Wrong N/W<br/>
          to automatically add or switch onto the {chainName(chainId)} network.</p>
        </p><br/>
      </div>
    );
  }
  
  export const GamblingHelper = () => {
    const {chainId} = useEthers();
    return (
      <div>
        <p>MoonFlip (MF) is a fun flipping game on the {chainData(chainId)} ecosystem. <br/><br/>
        We encourage all players to enjoy playing as a source <br/>
        of recreation within their manageable personal levels.<br/><br/>
        Flipping responsibly means regardless of win or lose,<br/>
        you won’t be impacting your ability to pay for life's essentials,<br/>
        or your ability to care for the important people in your life.<br/><br/>
        Stay in control of your flipping and have fun!</p><br/><br/>
        <p>
          <b>RESOURCES:</b><br/>
          • Call 1-800-522-4700<br/>
          • Chat npcgambling.org/chat<br/>
          • Text 1-800-522-4700<br/>
        </p>
      </div>
    );
  }

  export const ManualProcess = () => {

    const [yourLatest, setYourLatest] = useState({});
    const [transactionState, setTransactionState] = useState("-");
    const {chainId} = useEthers();

    async function callAPI(hash) {
      fetch(apiProcessTxn + chainData(chainId).toLowerCase() + "/" + hash, {
        method: "POST",
      }).then(response => {
        console.log(response);
        // too early
        if ((response.status) === 400) {
          setTransactionState("Not Processed.");
          return null;
        }
        else if (response.status === 429) {
          setTransactionState("Too Many Requests.");
          return null;
        } else if (response.status === 502) {
          setTransactionState("Server error - process later.");
          return null;
        } else if (response.status !== 200) {
          setTransactionState("Something else went down.");
          return null;
        } else {
          setTransactionState("Processed!");
        }
        return response.json()
      }).then(json => {
        if (json) {
          console.log("data:", json);

          setYourLatest({
            won: json.result,
            value: json.value,
            txHash: json.response_txn_hash
          });
        }
      })
    }

    function handleChange(event) {
      console.log(event.target.value);
      callAPI(event.target.value);
    }
  
    function handleSubmit(event) {
      callAPI(event.target.value);
    }

    return (
      <div>
        <p>Check the status of your transaction, or manually process it here.</p><br/>
        <p>
        Current Chain: <b>{chainName(chainId)} ({chainData(chainId)})</b>&nbsp;
        {chainLogo(chainId) !== UNKNOWN_NAME ? <img className="chain-logo" src={chainLogo(chainId)}/> : 
        <GiCrossedChains size={30}/>}<br/><br/>
        <form onSubmit={handleSubmit}>
            {/* Paste Flip Transaction Hash: */}
            <input className="enter-hash-box" type="text" name="hash" placeholder="Enter Flip Transaction Hash" onChange={handleChange}/>
        </form>
        </p>
        <p>
          Transaction State: <b>{transactionState}</b>
        </p>
        {transactionState === "Processed!" && <p>
          • Result: {yourLatest?.won ? "Win!" : "Loss."}<br/>
          • Amount: {Number(utils.formatEther(BigNumber.from(yourLatest?.value ?? 0))).toFixed(2)}&nbsp;{chainData(chainId)}<br/>
          • Payout TX Hash: {yourLatest?.won ? <a style={{'textDecoration': "none", "color": "cyan"}} target="_blank" href={`https://moonriver.moonscan.io/tx/${yourLatest?.txHash}`}>Explorer Link</a> : "None."}
        </p> }
      </div>
    );
  }

  export const NetworkSwitcher = () => {
    const {chainId, library, switchNetwork} = useEthers();
    return (
      <div>
        <p>Current Chain: <b>{chainName(chainId)} ({chainData(chainId)})</b>&nbsp;
        {chainLogo(chainId) !== UNKNOWN_NAME ? <img className="chain-logo" src={chainLogo(chainId)}/> : 
        <GiCrossedChains size={30}/>}<br/><br/>
        <div className="switcher-panel col">
          <div className="cruiser-font center-text">Available Chains:</div> <br/>
          <div className="row">
            {supportedChains.map((chain, index) => {
              return (
                <img className="switcher-logo" src={chainLogo(chain)} onClick={() => switchNetwork(chain)}/>
              )
            })}
          </div>
        </div>
        </p>
      </div>
    );
  }