import "./App.css";
import React, {useState, useEffect} from "react";
import Particles from "react-tsparticles";
import { Routes, Route } from "react-router-dom";
import { Home, Error } from "pages";
import ParticlesOptions from "./particles.json";
import LightParticlesOptions from "./particleslight.json";
import DarkModeToggle from "react-dark-mode-toggle";
import { GiSoundOn, GiSoundOff, GiMonkeyWrench, GiCrossedChains } from 'react-icons/gi';
import { Guide, GamblingHelper, RecentPlays, ManualProcess, NetworkSwitcher } from "components";
import { Modal } from "react-bootstrap";
import useSound from "use-sound";
import { ChainId, useEthers } from "@usedapp/core";
import { chainLogo, UNKNOWN_NAME } from "components/common/helpers";

function App() {

  const [darkMode, setDarkMode] = useState(true);
  const [music, setMusic] = useState(false);
  const [sounds, setSounds] = useState(true);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const [showGamblingModal, setShowGamblingModal] = useState(false);
  const [showRecentPlaysModal, setShowRecentPlaysModal] = useState(false);
  const [showManualProcessModal, setShowManualProcessModal] = useState(false);
  const [showNetworkSwitcherModal, setShowNetworkSwitcherModal] = useState(false);
  const [playBGMusic, { pause }] = useSound('/assets/sounds/Interstellar_Odyssey.ogg', {volume: 0.2, loop: true});
  const { chainId } = useEthers();

  const handleClose = () => {
    setShowGamblingModal(false);
    setShowGuideModal(false);
    setShowRecentPlaysModal(false);
    setShowManualProcessModal(false);
    setShowNetworkSwitcherModal(false);
  }
  
  useEffect(() => {
    if (music) {
      playBGMusic();
    } else {
      pause();
    }
  }, [music])

  return (
    <div className={`App ${!darkMode && 'light-mode'}`}>
      {/* <div className={`top-left`}>
        <img className="chain-logo" src={chainLogo(chainId)}/>
      </div> */}
      <div className={`top-right ${!darkMode && 'light-mode'}`}>
        <div className="hover-block sound-toggle" onClick={() => setShowNetworkSwitcherModal(true)}>
          {chainLogo(chainId) !== UNKNOWN_NAME ? <img className="chain-logo" src={chainLogo(chainId)}/> : 
          <GiCrossedChains size={30}/>}
          <div>CHAIN</div>
        </div>
        <div className="hover-block sound-toggle" onClick={() => setShowManualProcessModal(true)}><GiMonkeyWrench size={30}/><div>TOOLS</div></div>
        <div className="hover-block sound-toggle" onClick={() => setMusic(!music)}>{music ? <GiSoundOn size={30}/> : <GiSoundOff  size={30}/>}<div>MUSIC</div></div>
        <div className="hover-block sound-toggle" onClick={() => setSounds(!sounds)}>{sounds ? <GiSoundOn size={30}/> : <GiSoundOff  size={30}/>}<div>SOUND</div></div>
        <DarkModeToggle
          onChange={setDarkMode}
          checked={darkMode}
          size={80}
        />
      </div>
      <div className={`bottom-center ${!darkMode && 'light-mode'}`}>
        <div className="center-text hover-block lower-button-text" onClick={() => setShowGuideModal(true)}>How to Flip?</div>
        <div className="center-text hover-block lower-button-text" onClick={() => setShowGamblingModal(true)}>Flip Responsibly</div>
        <div className="center-text hover-block lower-button-text" onClick={() => setShowRecentPlaysModal(true)}>Game History</div>
      </div>
      <Particles options={darkMode ? ParticlesOptions : LightParticlesOptions}/>
      <Routes>
        <Route exact element={<Home darkMode={darkMode} setDarkMode={setDarkMode} sounds={sounds} setShowNetworkSwitcherModal={setShowNetworkSwitcherModal}/>} path="/" />
        <Route element={<Error />} path="*" />
      </Routes>
      <Modal show={showGuideModal} onHide={handleClose} centered className={!darkMode && 'light-mode'}>
        <Modal.Header>How to Flip?</Modal.Header>
        <Modal.Body><Guide/></Modal.Body>
        <Modal.Footer><button onClick={handleClose} className="modal-close-button">Close</button></Modal.Footer>
      </Modal>
      <Modal show={showGamblingModal} onHide={handleClose} centered className={!darkMode && 'light-mode'}>
        <Modal.Header>Flip Responsibly</Modal.Header>
        <Modal.Body><GamblingHelper/></Modal.Body>
        <Modal.Footer><button onClick={handleClose} className="modal-close-button">Close</button></Modal.Footer>
      </Modal>
      <Modal show={showRecentPlaysModal} onHide={handleClose} centered className={!darkMode && 'light-mode'}>
        <Modal.Header>Recent Games</Modal.Header>
        <Modal.Body><RecentPlays showing={showRecentPlaysModal}/></Modal.Body>
        <Modal.Footer><button onClick={handleClose} className="modal-close-button">Close</button></Modal.Footer>
      </Modal>
      <Modal show={showManualProcessModal} onHide={handleClose} centered className={!darkMode && 'light-mode'}>
        <Modal.Header>Verify/Process TX</Modal.Header>
        <Modal.Body><ManualProcess/></Modal.Body>
        <Modal.Footer><button onClick={handleClose} className="modal-close-button">Close</button></Modal.Footer>
      </Modal>
      <Modal show={showNetworkSwitcherModal} onHide={handleClose} centered className={!darkMode && 'light-mode'}>
        <Modal.Header>Select a Network</Modal.Header>
        <Modal.Body><NetworkSwitcher/></Modal.Body>
        <Modal.Footer><button onClick={handleClose} className="modal-close-button">Close</button></Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
