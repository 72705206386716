export const UNKNOWN_SYMBOL = "NONE";
export const UNKNOWN_NAME = "Unknown";

export const supportedNetworks = [
    {
        chainId: 1284,
        symbol: "GLMR",
        name: "Moonbeam",
        logo: './assets/images/chains/moonbeam.svg'
    },
    {
        chainId: 1285,
        symbol: 'MOVR',
        name: "Moonriver",
        logo: './assets/images/chains/moonriver.svg'
    },
    {
        chainId: 5,
        symbol: "GOETH",
        name: "Goerli",
        logo: './assets/images/chains/goerli.svg'
    },
    {
        chainId: 43114,
        symbol: "AVAX",
        name: "Avalanche",
        logo: './assets/images/chains/avalanche.svg'
    },

    // avax fuji
    { name: "Avalanche Fuji", symbol: "FUJI", chainId: 43113, logo: './assets/images/chains/avalanche.svg' }
]

export function chainData(_chainId) {
    let foundChain = supportedNetworks.find(({chainId}) => _chainId === chainId);
    return foundChain ? foundChain.symbol : UNKNOWN_SYMBOL;
}

export function chainName(_chainId) {
    let foundChain = supportedNetworks.find(({chainId}) => _chainId === chainId);
    return foundChain ? foundChain.name : UNKNOWN_NAME;
}

export function chainLogo(_chainId) {
    let foundChain = supportedNetworks.find(({chainId}) => _chainId === chainId);
    return foundChain ? foundChain.logo : UNKNOWN_NAME;
}