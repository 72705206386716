import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ethers, utils } from "ethers";
import { apiProcessTxn, apiUrl, defaultChain, isSupportedChainId, losingText, winningSounds, winningText } from "../../constants.js";
import { FLIPGAMEABI } from "abis/FlipGameABI";
import { ChainId, useEthers, getExplorerTransactionLink } from "@usedapp/core";
import { BigNumber } from "@ethersproject/bignumber";
import useSound from 'use-sound';
import FlippableCoin from "components/common/coin.js";
import { chainData } from "components/common/helpers.js";

const ResultBlock = ({ className, isFlipped, setIsFlipped, darkMode, sounds, flipFunction }) => {

  const [playWinSound] = useSound('/assets/sounds/win2.mp3');
  const [playLossSound] = useSound('/assets/sounds/lose.mp3');
  const [streak, setStreak] = useState(0);
  const [message, setMessage] = useState('Try Again');
  const [yourLatest, setYourLatest] = useState({});

  const [sentRequest, setSentRequest] = useState(false);
  const { library, account, chainId } = useEthers();

  function handleReturn() {
    setIsFlipped(false);
    setSentRequest(false);
    setYourLatest({}); // clear your latest

    flipFunction.resetState();

    console.log(isFlipped);
  }


  useEffect(() => {
    if (yourLatest !== {}) {
      if (yourLatest?.won) {
        setMessage(winningText[streak]);
        if (streak < 15) {
          setStreak(streak + 1);
        }
      } else {
        setMessage(losingText[losingText.length * Math.random() | 0]);
        setStreak(0);
      }
    }
  }, [yourLatest])



  useEffect(() => {
    if (account && isSupportedChainId(chainId) && !sentRequest && flipFunction.state.status === "Success") {
      console.log("sending request...")

      setSentRequest(true);

      fetch(apiProcessTxn + chainData(chainId).toLowerCase() + "/" + flipFunction.state.transaction.hash, {
        method: "POST",
        mode: "cors"
      }).then(response => {
        console.log(response);
        // too early
        if (response.status != 200) {
          console.log("transaction still pending...resubmitting");
//           setSentRequest(false);
          setTimeout( () => {
            setSentRequest(false)
          }, 1000); // 1 second retry
          return null;
        }
        return response.json()
      }).then(json => {
        if (json) {
          console.log("data:", json);

          setYourLatest({
            won: json.result,
            value: json.value,
            txHash: json.response_txn_hash
          });
        }
      })
    }

  }, [flipFunction.state, account, sentRequest]);

  useEffect(() => {
    if (account && (defaultChain === chainId) && flipFunction.state.status === "Success") {
      // const latestArgs = flipFunction?.events?.[0]?.args;
      // const latest = {
      //   won: latestArgs?.[1],
      //   value: latestArgs?.[3]
      // }
      // setYourLatest(latest);
      if (sounds && isFlipped) {
        if (yourLatest?.won) {
          playWinSound();
        } else if (yourLatest?.won === undefined) {
          //no sounds while loading
        } else {
          playLossSound();
        }
      }
    }
  }, [account, library, /*flipFunction.state, flipFunction.state.status, flipFunction.events */, yourLatest])


  return (
    /* visual block html */
    <div className={`${className}`}>
      <div className="block-selection">
        <strong className="logo">
          <Link to={"/"}>
            <img src="/assets/images/banner_no_bg.png" alt="image description" />
          </Link>
        </strong>
        <div className={`box-area ${!darkMode ? 'box-area-light' : ''}`}>
          <div className="bars-holder">
            <span className="bars">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </span>
            <span className="bars bottom">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </span>
            <strong className={`coin-holder`}>
              {<img className={`img-spin`} src="/assets/images/coinfront.png" alt="image description" />}
            </strong>
            <div className="result-box">
              {yourLatest?.won === undefined && <strong className={`title ${darkMode && 'dark-mode-text'}`}>Loading...</strong>}
              {yourLatest?.won !== undefined && <strong className={`title ${darkMode && 'dark-mode-text'}`}>You {yourLatest?.won ? "Won" : "Lost"}</strong>}
              {yourLatest?.won !== undefined && <a style={{'textDecoration': "none"}} target="_blank" href={yourLatest?.won ? getExplorerTransactionLink(yourLatest?.txHash, chainId ?? defaultChain) : ''}>
                <strong className={`price ${yourLatest?.won ? '' : 'lost'}`}>{Number(utils.formatEther(BigNumber.from(yourLatest?.value ?? 0))).toFixed(2)}&nbsp;{chainData(chainId)}</strong>
              </a>}
              {yourLatest?.won === undefined && <strong className={`price loading`}>*****</strong>}
            </div>
            <span className={`heading ${darkMode && 'dark-mode-text'}`}>{yourLatest?.won === undefined ? 'Feelin\' lucky? Let\'s find out!' : message}</span>
            <div onClick={handleReturn} className={`hexa-button ${!isFlipped && 'hide-mobile'}`}>
              <span className="hexa-inner left-arrow"> </span>
              <span className="hexa-inner right-arrow"> </span>
              <span className="hexa-button-inner1">
                <span className="hexa-inner1 left-arrow"> </span>
                <span className="hexa-inner1 right-arrow"> </span>
                <span className="hexa-button-inner2">
                  <span className="hexa-inner2 left-arrow"> </span>
                  <span className="hexa-inner2 right-arrow"> </span>
                  <span className="hexa-button-inner3">
                    <span className="hexa-inner3 left-arrow"> </span>
                    <span className="hexa-inner3 right-arrow"> </span>
                  </span>
                </span>
              </span>
              <span className="text btn-small">Double Or Nothing</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* text block styles */
export default styled(ResultBlock)``;
