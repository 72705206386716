import React from "react";
import styled from "styled-components";
import { Result_block } from "components";

const Product = ({ className }) => {
  return (
    /* product page html */
    <div className={className}>
      <Result_block />
    </div>
  );
};

/* product page styles */
export default styled(Product)``;
